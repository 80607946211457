import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);

const checkAuth = async function(to, from, next) {
    if(!window.localStorage.getItem("pharmToken")) {
      window.location.href = "/";
    }else{
      next();
    }
}

export default new Router({
    mode: 'history', 
    scrollBehavior() {
        return window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    routes: [

        // AUTH
        {
            path: '/',
            name: ['Login'],
            meta: {layout: 'userpages'},
            component: () => import('../Pages/Auth/Login.vue'),
        },
        {
            path: '/sign-up',
            name: ['Sign Up'],
            meta: {layout: 'userpages'},
            component: () => import('../Pages/Auth/Sign-Up.vue'),
        },
        {
            path: '/verify-account',
            name: ['Verify Account'],
            meta: {layout: 'userpages'},
            component: () => import('../Pages/Auth/VerifyAccount.vue'),
        },
        {
            path: '/forgot-password',
            name: ['Forgot Password'],
            meta: {layout: 'userpages'},
            component: () => import('../Pages/Auth/ForgotPassword.vue'),
        },


        // Dashboards
        {
            path: '/dashboard',
            name: ['Dashboard'],
            beforeEnter: checkAuth,
            component: () => import('../Pages/Dashboard/Dashboard.vue'),
        },
        {
            path: '/dashboard-analysis',
            name: ['Dashboard Analysis'],
            beforeEnter: checkAuth,
            component: () => import('../Pages/Dashboard/DashboardAnalysis.vue'),
        },



        // SETTINGS
        {
            path: '/settings/medicines',
            name: ["Settings", 'Medicines'],
            beforeEnter: checkAuth,
            component: () => import('../Pages/Settings/Medicine.vue'),
        },
        {
            path: '/settings/product-types',
            name: ["Settings", 'product Types'],
            beforeEnter: checkAuth,
            component: () => import('../Pages/Settings/ProductType.vue'),
        },
        {
            path: '/settings/expense-categories',
            name: ["Settings", 'Expense Categories'],
            beforeEnter: checkAuth,
            component: () => import('../Pages/Settings/ExpenseCategory.vue'),
        },
        {
            path: '/settings/medicine-units',
            name: ["Settings", 'Medicine Units'],
            beforeEnter: checkAuth,
            component: () => import('../Pages/Settings/MedicineUnit.vue'),
        },
        {
            path: '/settings/medicine-routes',
            name: ["Settings", 'Medicine Routes'],
            beforeEnter: checkAuth,
            component: () => import('../Pages/Settings/MedicineRoute.vue'),
        },
        {
            path: '/settings/packages',
            name: ["Settings", 'Packages'],
            beforeEnter: checkAuth,
            component: () => import('../Pages/Settings/Package.vue'),
        },


        // USER MANAGEMENT
        {
            path: '/user-management/roles',
            name: ["User Management", 'Roles'],
            beforeEnter: checkAuth,
            component: () => import('../Pages/User/Role.vue'),
        },
        {
            path: '/user-management/permissions',
            name: ["User Management", 'Permission'],
            beforeEnter: checkAuth,
            component: () => import('../Pages/User/Permission.vue'),
        },
        {
            path: '/user-management/role-permissions',
            name: ["User Management", 'Role Permission'],
            beforeEnter: checkAuth,
            component: () => import('../Pages/User/RolePermission.vue'),
        },
        {
            path: '/user-management/users',
            name: ["User Management", 'Users'],
            beforeEnter: checkAuth,
            component: () => import('../Pages/User/User.vue'),
        },
        {
            path: '/user-management/users/view/:uuid',
            name: ["User Management", 'Users', 'View User'],
            beforeEnter: checkAuth,
            component: () => import('../Pages/User/ViewUser.vue'),
        },
        {
            path: '/user-management/change-temporary-password',
            name: ["User Management", 'Change Temporary Password'],
            beforeEnter: checkAuth,
            component: () => import('../Pages/User/ChangeTemporaryPassword.vue'),
        },
        {
            path: '/user-management/change-password',
            name: ["User Management", 'Change Password'],
            beforeEnter: checkAuth,
            component: () => import('../Pages/User/ChangePassword.vue'),
        },
        {
            path: '/user-management/profile',
            name: ["User Management", 'Profile'],
            beforeEnter: checkAuth,
            component: () => import('../Pages/User/Profile.vue'),
        },




        // PHARMACY
        {
            path: '/pharmacy-management/sales',
            name: ["Pharmacy Management", 'Sales'],
            beforeEnter: checkAuth,
            component: () => import('../Pages/Pharmacy/Sale.vue'),
        },
        {
            path: '/pharmacy-management/sales/add-new',
            name: ["Pharmacy Management", 'Sales', 'Add New Sale'],
            beforeEnter: checkAuth,
            component: () => import('../Pages/Pharmacy/AddSale.vue'),
        },
        {
            path: '/pharmacy-management/sales/view/:uuid',
            name: ["Pharmacy Management", 'Sales', 'View Sale Details'],
            beforeEnter: checkAuth,
            component: () => import('../Pages/Pharmacy/ViewSale.vue'),
        },
        {
            path: '/pharmacy-management/products',
            name: ["Pharmacy Management", 'Products'],
            beforeEnter: checkAuth,
            component: () => import('../Pages/Pharmacy/Product.vue'),
        },
        {
            path: '/pharmacy-management/products/view/:uuid',
            name: ["Pharmacy Management", 'Products', 'Product Details'],
            beforeEnter: checkAuth,
            component: () => import('../Pages/Pharmacy/ProductDetail.vue'),
        },
        {
            path: '/pharmacy-management/products/view/:uuid/batch/:batchUuid',
            name: ["Pharmacy Management", 'Products', 'Product Details', 'Batch Details'],
            beforeEnter: checkAuth,
            component: () => import('../Pages/Pharmacy/ProductBatchDetail.vue'),
        },




        // EXPENSE
        {
            path: '/pharmacy-management/expenses',
            name: ["Pharmacy Management", 'Expenses'],
            beforeEnter: checkAuth,
            component: () => import('../Pages/Pharmacy/Expense.vue'),
        },
        {
            path: '/pharmacy-management/expenses/view/:uuid',
            name: ["Pharmacy Management", 'Expense Details'],
            beforeEnter: checkAuth,
            component: () => import('../Pages/Pharmacy/ExpenseDetail.vue'),
        },




        // REQUESTS
        {
            path: '/request-management/drug-batches',
            name: ["Request Management", 'Drug Batches'],
            beforeEnter: checkAuth,
            component: () => import('../Pages/Request/DrugBatchRequest.vue'),
        },
        {
            path: '/request-management/drug-batches/details/:uuid',
            name: ["Request Management", 'Drug Batches', 'Details'],
            beforeEnter: checkAuth,
            component: () => import('../Pages/Request/DrugBatchRequestDetail.vue'),
        },
        {
            path: '/request-management/expenses',
            name: ["Request Management", 'Expenses'],
            beforeEnter: checkAuth,
            component: () => import('../Pages/Request/ExpenseRequest.vue'),
        },
        {
            path: '/request-management/expenses/details/:uuid',
            name: ["Request Management", 'Expenses', 'Details'],
            beforeEnter: checkAuth,
            component: () => import('../Pages/Request/ExpenseRequestDetail.vue'),
        },

        
    ]
})
