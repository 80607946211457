<template>
    <div class="app-sidebar sidebar-shadow" @mouseover="toggleSidebarHover('add','closed-sidebar-open')" @mouseleave="toggleSidebarHover('remove','closed-sidebar-open')">
        <div class="app-header__logo">
            <img width="128" class="text-center" src="@/assets/images/logo.png" alt="">
            <div class="header__pane ml-auto">
                <button type="button" class="hamburger close-sidebar-btn hamburger--elastic" v-bind:class="{ 'is-active' : isOpen }" @click="toggleBodyClass('closed-sidebar')">
                    <span class="hamburger-box">
                        <span class="hamburger-inner"></span>
                    </span>
                </button>
            </div>
        </div>
        <div class="app-sidebar-content pt-3">
            <VuePerfectScrollbar class="app-sidebar-scroll" v-once>
                <sidebar-menu showOneChild v-if="menu != null && menu.length > 0" :menu="menu"/>
            </VuePerfectScrollbar>
        </div>

    </div>
</template>

<script>
    import {SidebarMenu} from 'vue-sidebar-menu'
    import VuePerfectScrollbar from 'vue-perfect-scrollbar'

    export default {
        components: {
            SidebarMenu,
            VuePerfectScrollbar
        },
        data() {
            return {
                isOpen: false,
                sidebarActive: false,

                menu: [],
                collapsed: true,

                windowWidth: 0,

            }
        },
        props: {
            sidebarbg: String,
        },
        methods: {

            toggleBodyClass(className) {
                const el = document.body;
                this.isOpen = !this.isOpen;

                if (this.isOpen) {
                    el.classList.add(className);
                } else {
                    el.classList.remove(className);
                }
            },
            toggleSidebarHover(add, className) {
                const el = document.body;
                this.sidebarActive = !this.sidebarActive;

                this.windowWidth = document.documentElement.clientWidth;

                if (this.windowWidth > '992') {
                    if (add === 'add') {
                        el.classList.add(className);
                    } else {
                        el.classList.remove(className);
                    }
                }
            },
            getWindowWidth() {
                const el = document.body;

                this.windowWidth = document.documentElement.clientWidth;

                if (this.windowWidth < '1350') {
                    el.classList.add('closed-sidebar', 'closed-sidebar-md');
                } else {
                    el.classList.remove('closed-sidebar', 'closed-sidebar-md');
                }
            },

            getUserPermissions() {
                let sidebarLinks = []

                const user = JSON.parse(localStorage.getItem("pharmUser"));

                // eslint-disable-next-line no-console
				console.log(user)

                const role = user != null && user.role != null ? user.role.codeName : null;

                if(role != null && !user.firstLogin) {

                    let modules = user.role.permissions !== null && user.role.permissions.length > 0 ? user.role.permissions.map(perm => perm.module.toLowerCase()) : [];

                    if(modules != null || modules.length != 0) {

                        if(modules.includes('dashboard')) {
                            sidebarLinks.push(
                                {
                                    header: true,
                                    title: 'Summary',
                                },
                                {
                                    title: 'Dashboards',
                                    icon: 'pe-7s-rocket',
                                    child: [
                                        {
                                            title: 'Overall',
                                            href: '/dashboard',
                                        },
                                            {
                                            title: 'Analysis',
                                            href: '/dashboard-analysis',
                                        }
                                    ]
                                    // href: '/dashboard',

                                },
                            )
                        }

                        sidebarLinks.push(
                            {
                                header: true,
                                title: 'MAIN',
                            },
                        )

                        if(modules.includes('pharmacy')) {
                            let submenuLinks = []

                            if(modules.includes('sales')) {
                                submenuLinks.push(
                                    {
                                        title: 'Sales',
                                        href: '/pharmacy-management/sales',
                                    }
                                )
                            }

                            if(modules.includes('stocks')) {
                                submenuLinks.push(
                                    {
                                        title: 'Products',
                                        href: '/pharmacy-management/products',
                                    },
                                )
                            }

                            if(modules.includes('expense')) {
                                submenuLinks.push(
                                    {
                                        title: 'Expenses',
                                        href: '/pharmacy-management/expenses',
                                    },
                                )
                            }

                            sidebarLinks.push(
                                {
                                    icon: 'pe-7s-shopbag',
                                    title: 'Pharmacy',
                                    child: submenuLinks
                                },
                            )
                        }


                        // if(modules.includes('request')) {
                        //     let submenuLinks = []

                        //     if(modules.includes('medicine-request')) {
                        //         submenuLinks.push(
                        //             {
                        //                 title: 'Drug Batches',
                        //                 href: '/request-management/drug-batches',
                        //             },
                        //         )
                        //     }

                        //     if(modules.includes('expense-request')) {
                        //         submenuLinks.push(
                        //             {
                        //                 title: 'Expenses',
                        //                 href: '/request-management/expenses',
                        //             },
                        //         )
                        //     }

                        //     sidebarLinks.push(
                        //         {
                        //             icon: 'pe-7s-ribbon',
                        //             title: 'Request',
                        //             child: submenuLinks
                        //         },
                        //     )
                        // }


                        if(modules.includes('user-management')) {

                            sidebarLinks.push(
                                {
                                    icon: 'pe-7s-users',
                                    title: 'User Management',
                                    child: [
                                        {
                                            title: 'Users',
                                            href: '/user-management/users',
                                        },
                                        {
                                            title: 'Role',
                                            href: '/user-management/roles',
                                        },
                                        // {
                                        //     title: 'Permission',
                                        //     href: '/user-management/permissions',
                                        // },
                                        // {
                                        //     title: 'Role Permission',
                                        //     href: '/user-management/role-permissions',
                                        // },
                                    ],
                                },
                            )
                        }


                        if(modules.includes('settings')) {

                            sidebarLinks.push(
                                {
                                    icon: 'pe-7s-settings',
                                    title: 'Settings',
                                    child: [
                                        // {
                                        //     title: 'Medicine',
                                        //     href: '/settings/medicines',
                                        // },
                                        {
                                            title: 'Product Types',
                                            href: '/settings/product-types',
                                        },
                                        {
                                            title: 'Expense Categories',
                                            href: '/settings/expense-categories',
                                        },
                                    ],
                                },
                            )
                        }


                    }

                }

                // eslint-disable-next-line no-console
                // console.log(this.menu)
                this.menu = sidebarLinks;
            }
        },
        mounted() {
            this.$nextTick(function () {
                window.addEventListener('resize', this.getWindowWidth);

                //Init
                this.getWindowWidth()
            })

            
        },
        created() {
            this.getUserPermissions();
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.getWindowWidth);
        }
    }
</script>
